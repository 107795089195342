import Swiper from 'swiper';
import {Navigation, EffectCreative} from 'swiper/modules';

// Notices
document.addEventListener('DOMContentLoaded', () => {
  const notices = document.querySelector('.js-notices');
  let activeSlide = document.querySelector('.js-active-slide');
  const totalSlides = document.querySelector('.js-total-slides');

  if (notices) {
    const swiper = new Swiper(notices, {
      modules: [Navigation, EffectCreative],
      loop: false,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.js-swiper-notice-next',
        prevEl: '.js-swiper-notice-prev'
      },
      effect: 'creative',
				creativeEffect: {
					prev: {
            translate: [-70, 0, 0],
            rotate: [0, 0, -10],
            opacity: 0,
					},
					next: {
            scale: 0.9,
            opacity: 0,
					},
				},
    });

    let indexActif = swiper.activeIndex;
    let nbSlides = swiper.slides.length;
    activeSlide.innerHTML = indexActif + 1;
    totalSlides.innerHTML = nbSlides;
    
    swiper.on('slideChange', () => {
      let indexActif = swiper.activeIndex;
      let nbSlides = swiper.slides.length;
      activeSlide.innerHTML = indexActif + 1;
      totalSlides.innerHTML = nbSlides;
    });
  }
});  

// Services
document.addEventListener('DOMContentLoaded', () => {
  const services = document.querySelector('.js-services');
  let activeSlide = document.querySelector('.js-active-services-slide');
  const totalSlides = document.querySelector('.js-total-services-slides');
  let swiper = null; 
  let swiperInitialized = false;

  if(services) {
    function initSwiper() {
      if (window.innerWidth < 1024 && !swiper) {
        swiper = new Swiper(services, {
          modules: [Navigation],
          spaceBetween: 24,
          slidesPerView: 'auto',
          slidesPerGroupAuto: false,
          centeredSlides: true,
          autoHeight: true,
          navigation: {
            nextEl: '.js-swiper-services-next',
            prevEl: '.js-swiper-services-prev'
          },
        });
        swiperInitialized = true;
  
        let indexActif = swiper.activeIndex;
        activeSlide.innerHTML = indexActif + 1;
        let nbSlides = swiper.slides.length;
        totalSlides.innerHTML = nbSlides;
  
        swiper.on('slideChange', () => {
          let indexActif = swiper.activeIndex;
          activeSlide.innerHTML = indexActif + 1;
        });
      } else if (window.innerWidth >= 1024 && swiperInitialized) {
        swiper.params.slidesPerView = 'auto';
        swiper.params.spaceBetween = 24;
        swiper.params.slidesPerGroupAuto = false;
        swiper.params.centeredSlides = true;
        swiper.update();
        swiperInitialized = false;
      }
    }
  
    initSwiper();
  
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1024 && !swiper) {
        initSwiper();
      } else if (window.innerWidth >= 1024) {
        if (swiper) {
          swiper.destroy(true, true);
          swiper = null;
        }
        swiperInitialized = false;
      }
    });
  }

});



// Location gallery
document.addEventListener('DOMContentLoaded', () => {
  const gallery = document.querySelector('.js-gallery');
  let activeSlide = document.querySelector('.js-active-gallery-slide');
  const totalSlides = document.querySelector('.js-total-gallery-slides');

  if (gallery) {
    const swiper = new Swiper(gallery, {
      modules: [Navigation],
      spaceBetween: 24,
      navigation: {
        nextEl: '.js-swiper-gallery-next',
        prevEl: '.js-swiper-gallery-prev'
      },
    });
    
    let indexActif = swiper.activeIndex;
    activeSlide.innerHTML = indexActif + 1;
    let nbSlides = swiper.slides.length;
    totalSlides.innerHTML = nbSlides;

    swiper.on('slideChange', () => {
      let indexActif = swiper.activeIndex;
      activeSlide.innerHTML = indexActif + 1;
    });
  }
});  


// Room
document.addEventListener('DOMContentLoaded', () => {
  const slider = document.querySelector('.js-slider');
  let activeSlide = document.querySelector('.js-active-slider-slide');
  const totalSlides = document.querySelector('.js-total-slider-slides');

  if (slider) {
    const swiper = new Swiper(slider, {
      modules: [Navigation],
      spaceBetween: 24,
      slidesPerView: 1,
      centeredSlides: true,
      navigation: {
        nextEl: '.js-swiper-slider-next',
        prevEl: '.js-swiper-slider-prev'
      },
    });

    
    let indexActif = swiper.activeIndex;
    activeSlide.innerHTML = indexActif + 1;
    let nbSlides = swiper.slides.length;
    totalSlides.innerHTML = nbSlides;

    swiper.on('slideChange', () => {
      let indexActif = swiper.activeIndex;
      activeSlide.innerHTML = indexActif + 1;
    });
  }
});  
