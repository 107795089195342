document.addEventListener("DOMContentLoaded", () => {

  // Open and close megamenu
  let buttons = document.querySelectorAll(".js-nav-button");
  let megamenus = document.querySelectorAll(".js-megamenu");

  function closeMegamenu(menu) {
    menu.classList.add("close");
    menu.classList.add("none");
  }

  document.addEventListener("click", function (event) {
    let btnActive = document.querySelector(".js-nav-button.active");
    let megamenuActive = document.querySelector(".js-megamenu:not(.close)");
    if (megamenuActive && btnActive && !megamenuActive.contains(event.target)) {
      buttons.forEach((btn) => {
        btn.classList.remove("active");
      });
      megamenus.forEach((megamenu) => {
        megamenu.classList.add("none");
        megamenu.classList.add("close");
      });
    }
  });

  buttons.forEach((btn) => {
    btn.addEventListener("click", () => {
      let btnControl = btn.getAttribute("aria-controls");
      let megamenuToControl = document.getElementById(btnControl);

      if (btn.classList.contains("active")) {
        btn.classList.remove("active");
        megamenus.forEach((megamenu) => {
          closeMegamenu(megamenu);
        });
      } else {
        buttons.forEach((otherBtn) => {
          if (otherBtn !== btn) {
            otherBtn.classList.remove("active");
          }
          megamenus.forEach((megamenu) => {
            if (megamenu !== megamenuToControl) {
              closeMegamenu(megamenu);
            }
          });
        });

        btn.classList.add("active");

        if (megamenuToControl) {
          megamenuToControl.classList.remove("none");
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              megamenuToControl.classList.remove("close");
            });
          });
        }
      }
    });
  });

  // megamenus.forEach((megamenu) => {
  //   let megamenuLastItem =
  //     megamenu.lastElementChild.lastElementChild.lastElementChild;
  //   megamenuLastItem.addEventListener("focusout", function () {
  //     let btnActive = document.querySelector(".js-nav-button.active");
  //     if (btnActive) {
  //       btnActive.classList.remove("active");
  //       closeMegamenu(megamenu);
  //     }
  //   });
  // });

  // open and close menu at mobile
  let menuButton = document.querySelector(".js-menu-button");
  let menuButtonContent = document.querySelector(".js-menu-button-label");
  let header = document.querySelector(".js-header");
  let mainNavigation = document.querySelector(".js-navigation");
  const $html = document.documentElement;

  menuButton.addEventListener("click", () => {
    menuButtonContent.innerHTML = (menuButtonContent.innerHTML === "Fermer") ? "Menu" : "Fermer";
    header.classList.toggle("open");
    $html.classList.toggle("no-scroll");
    let expanded = menuButton.getAttribute("aria-expanded") === "true" || false;
    menuButton.setAttribute("aria-expanded", !expanded);
  });

});
