document.addEventListener("DOMContentLoaded", () => {
    const cards = document.querySelectorAll(".js-highlights-item");
    const wrapper = document.querySelector(".js-highlights");

    if (wrapper) {
        wrapper.addEventListener("mousemove", function ($event) {
            cards.forEach((card) => {
                const rect = card.getBoundingClientRect();
                const x = $event.clientX - rect.left;
                const y = $event.clientY - rect.top;
    
                card.style.setProperty("--xPos", `${x}px`);
                card.style.setProperty("--yPos", `${y}px`);
            });
        });
    } 
} )