/**
 * Name: Details
 * Description: Collapsing section
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Details extends Component {
  static settings = {
    open: false,
    rememberExpanded: false,
    hasAdditionalControl: false,
    i18n: {
      hideSection: 'Masquer la section',
      showSection: 'Afficher la section',
    }
  };

  #control;
  #content;
  #label;

  #handleControlClick;
  #handleContentBeforeMatch;
  #handleHashChange;
  #handleToggle;

  constructor (element, options = {}) {
    super(element, {
      ...Details.settings,
      ...options
    });

    this.#control = this.element.querySelector('.js-details-control');
    this.#content = this.element.querySelector('.js-details-content');

    if (!this.element.getAttribute('id')) {
      throw new Error(`Details error: An id is required on the '.js-details' element.`, this.element);
    }

    if (!this.#control) {
      throw new Error(`Details error: A control with the 'js-details-control' class is missing.`, this.element);
    }

    if (!this.#content) {
      throw new Error(`Details error: A content with the 'js-details-content' class is missing.`, this.element);
    }

    if (!this.isNativeDetails) {
      this.#label = this.element.querySelector('.js-details-label');

      if (!this.#label) {
        throw new Error(`Details error: A label with the 'js-details-label' class is missing.`, this.element);
      }
    }

    if (this.settings.rememberExpanded) {
      try {
        this.sessionStorageValue;
      } catch (e) {
        this.settings.rememberExpanded = false;
        console.error('sessionStorage is not usable.');
      }
    }

    this.#handleControlClick = this._handleControlClick.bind(this);
    this.#handleContentBeforeMatch = this._handleContentBeforeMatch.bind(this);
    this.#handleHashChange = this._handleHashChange.bind(this);
    this.#handleToggle = this._handleToggle.bind(this);
  }

  get control () {
    return this.#control;
  }

  get content () {
    return this.#content;
  }

  get expanded () {
    let state;

    if (this.isNativeDetails) {
      state = this.element.open;
    } else {
      state = this.#control.getAttribute('aria-expanded') === 'true';
    }

    return state;
  }

  get sessionStorageValue () {
    return sessionStorage.getItem(this.element.getAttribute('id'));
  }

  get isNativeDetails () {
    return this.element.tagName.toLowerCase() === "details";
  }

  set sessionStorageValue (value) {
    sessionStorage.setItem(this.element.getAttribute('id'), value);
  }

  _handleControlClick (e) {
    this.toggle();

    if (this.additionalControl && e.target === this.additionalControl) {
      this.#control.focus();
    }
  }

  _handleContentBeforeMatch (e) {
    this.open();
  }

  _handleHashChange (e) {
    if (this.element.id === location.hash.substring(1)) {
      this.open();
    }
  }

  _handleToggle (e) {
    const state = this.expanded ? 'open' : 'close';

    if (this.settings.rememberExpanded) {
      this.sessionStorageValue = state;
    }

    this.fire(state, this);
  }

  convertControlToButton () {
    let control = document.createElement('button');
    control.type = 'button';
    const attributes = this.#control.getAttributeNames().reduce((acc, name) => {
      control.setAttribute(name, this.#control.getAttribute(name));
    }, {});
    control.innerHTML = this.#control.innerHTML;
    this.#control.replaceWith(control);
    this.#control = control;
  }

  resetControl () {
    let control = document.createElement('span');
    const attributes = this.#control.getAttributeNames().reduce((acc, name) => {
      control.setAttribute(name, this.#control.getAttribute(name));
    }, {});
    control.removeAttribute('type');
    control.innerHTML = this.#control.innerHTML;
    this.#control.replaceWith(control);
    this.#control = control;
  }

  addAdditionalControl () {
    const additionalControl = document.createElement('button');
    additionalControl.type = 'button';
    additionalControl.classList.add('c-details__button');
    additionalControl.setAttribute('aria-controls', this.#content.getAttribute('id'));

    const marker = this.#control.querySelector('.js-details-marker');

    additionalControl.innerHTML = `${ this.settings.i18n.hideSection } ${ marker.outerHTML }`;

    this.#content.appendChild(additionalControl);
    this.additionalControl = additionalControl;
    this.additionalControl.addEventListener('click', this.#handleControlClick);
  }

  removeAdditionalControl () {
    this.additionalControl.removeEventListener('click', this.#handleControlClick);
    this.additionalControl.remove();
  }

  toggle () {
    this.expanded ? this.close() : this.open();
  }

  open () {
    if (this.isNativeDetails) {
      this.element.open = true;
    } else {
      this.#control.setAttribute('aria-expanded', 'true');
      this.#content.removeAttribute('hidden');
      this.#label.innerHTML = this.settings.i18n.hideSection;
    }

    if (this.settings.rememberExpanded) {
      this.sessionStorageValue = 'open';
    }

    this.fire('open', this);
  }

  close () {
    if (this.isNativeDetails) {
      this.element.open = false;
    } else {
      this.#control.setAttribute('aria-expanded', 'false');
      this.#content.setAttribute('hidden', 'until-found');
      this.#label.innerHTML = this.settings.i18n.showSection;
    }

    if (this.settings.rememberExpanded) {
      this.sessionStorageValue = 'close';
    }

    this.fire('close', this);
  }

  mount () {
    window.addEventListener('hashchange', this.#handleHashChange);

    if (!this.isNativeDetails) {
      this.convertControlToButton();
      this.#control.setAttribute('aria-controls', this.#content.getAttribute('id'));
      this.#content.setAttribute('aria-labelledby', this.#control.getAttribute('id'));

      this.#control.addEventListener('click', this.#handleControlClick);
      this.#content.addEventListener('beforematch', this.#handleContentBeforeMatch);
    } else {
      this.element.addEventListener('toggle', this.#handleToggle);
    }

    if (this.settings.hasAdditionalControl) {
      this.addAdditionalControl();
    }

    if (this.element.id === location.hash.substring(1)) {
      this.open();
    } else if (this.settings.rememberExpanded && this.sessionStorageValue) {
      this.sessionStorageValue === 'open' ? this.open() : this.close();
    } else {
      this.settings.open ? this.open() : this.close();
    }

    super.mount();
  }

  unmount () {
    window.removeEventListener('hashchange', this.#handleHashChange);

    if (!this.isNativeDetails) {
      this.#content.removeEventListener('beforematch', this.#handleContentBeforeMatch);
      this.#control.removeEventListener('click', this.#handleControlClick);

      this.#content.removeAttribute('hidden');
      this.#content.removeAttribute('aria-labelledby');
      this.#control.removeAttribute('tabindex');
      this.#control.removeAttribute('role');
      this.#control.removeAttribute('aria-controls');
      this.#control.removeAttribute('aria-expanded');
      this.resetControl();
    } else {
      this.element.addEventListener('toggle', this.#handleToggle);
    }

    if (this.settings.rememberExpanded) {
      sessionStorage.removeItem(this.element.getAttribute('id'));
    }

    if (this.additionalControl) {
      this.removeAdditionalControl();
    }

    super.unmount();
  }
}