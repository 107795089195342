document.addEventListener("DOMContentLoaded", () => {
    const contactRoom = document.querySelector('.js-contact-form');
    const contactButton = document.querySelector('.js-contact-button');
    const footer = document.querySelector('footer');


    if (contactButton) {
        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    contactButton.classList.add('hide');
                    contactButton.classList.add('none');
                } else {
                    contactButton.classList.remove('hide');
                    contactButton.classList.remove('none');
                }
            });
        };
    
        const observer = new IntersectionObserver(observerCallback);
    
        observer.observe(contactRoom);
        observer.observe(footer);
    }
});